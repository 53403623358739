.topnav {
    overflow: hidden;
    background-color: aliceblue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    -moz-box-shadow: inset 0 -10px 10px -10px #000000;
    -webkit-box-shadow: inset 0 -10px 10px -10px #000000;
    box-shadow: inset 0 -10px 10px -10px #000000;
}

.topnav img {
    width: 100px;
    padding: 0px 16px;
}

.topnav h3:hover {
    background-color: var(--secondary-orange);
    color: aliceblue;
    border-radius: 5px;
}

.topnav h4 {
    color: var(--secondary-orange);
}

.timers {
    color: var(--secondary-orange);
    font-weight: bold;
    font-size: 16pt;
}

.userProfile h3 {
    color: var(--main-orange);
    font-weight: bold;
    float: left;
    text-align: center;
    padding: 29px 26px;
    text-decoration: none;
    font-size: 15px;
    cursor: pointer;
}


@media (orientation:portrait) {
    .topnav.responsive {
        position: relative;
    }

    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }

    .timers {
        font-size: 11pt;
    }
}



@media screen and (max-width: 800px) and (orientation:portrait) {

    .topnav {
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }
}
.codeContainer {
    display: block;
    flex-direction: column;
    align-items: center;
    background-color: var(--secondary-orange);
    border-radius: 15px;
    box-shadow: inset 0px 10px 8px 0px #03000048, inset 0px -10px 8px 0px #03000048;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 30px;
    height: 30%;
}

.closeCode {
    position: initial;
    cursor: pointer;
    padding-right: 1.4rem;
}

.codeContainer span {
    display: flex;
    justify-content: flex-end;
    margin-left: 85%;
}

.codeBody {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.codeForm {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding-bottom: 25px;
    align-items: center;
    width: 300px;
}

.codeForm input {
    height: 21pt;
    width: 80%;
    color: black;
}

.codeForm select {
    color: black;
}


.codeForm button {
    margin-top: 4%;
    width: 35%;
    padding: 5px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
}

.formerror {
    color: rgb(255, 229, 229);
    position: relative;
    width: 100%;
    right: 59%;
    padding-top: 5%;
    padding-bottom: 5%;
}
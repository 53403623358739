.horizontal {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.storename {
  width: 15%;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  color: #e95725;
}

li {
  list-style: none;
}

.storeslider {
  width: 85%;
}

@media only screen and (max-width: 900px) {
  .horizontal {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .storename {
    margin-bottom: 40px;
    width: 100%;
  }

  .storeslider {
    width: 100%;
  }
}
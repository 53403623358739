.content.newT h2 {
    font-size: 36pt;
    background-color: #ffebd5;
    opacity: 1;
    background-image: radial-gradient(#459ed5 1.6500000000000001px, #ffebd5 1.6500000000000001px);
    background-size: 33px 33px;
    height: 50%;
    border-radius: 45px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: transform 0.5s;
}

.link {
    cursor: pointer;
}

.content {
    box-shadow: inset 0px 10px 8px 0px #03000048, inset 0px -10px 8px 0px #03000048;
    width: 70%;
    padding: 60px 40px;
    text-align: center;
    background: var(--secondary-orange);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3%;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    border-radius: 15px;
}

.content h1 {
    font-size: 2.8em;
    padding: 5px 0;
    font-weight: 800;
    margin-top: -5%;
}

.content h2 {
    font-size: 12pt;
    font-weight: 100;
    letter-spacing: 5px;
}

.content h3 {
    display: flex;
    justify-content: flex-end;
    margin-left: 90%;
    margin-top: -3%;
    cursor: pointer;
    transition: transform 0.5s;
}

.content h3:hover {
    transform: scale(1.3);
}

.link {
    background-color: #ffebd5;
    color: var(--secondary-orange);
    font-weight: bold;
    padding: 15px 0px;
    border-radius: 5px;
}

@media (orientation:portrait) {
    .content {
        padding: 60px 20px;
    }

    .content h3 {
        margin-left: 90%;
        margin-top: -15%;
    }

}
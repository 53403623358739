

.dniContainer {
    margin-left: auto;
    margin-right: auto;
    background-color: var(--secondary-orange);
    padding: 10px;
    border-radius: 15px;
    box-shadow: inset 0px 10px 8px 0px #03000048, inset 0px -10px 8px 0px #03000048;
}

.dniContainer h3 {
    display: block;
    float: right;
    margin-top: 3px;
    margin-right: 10px;
    cursor: pointer;
    transition: transform 0.5s;
}

.dniContainer h3:hover {
    transform: scale(1.3);
}

.dniBody {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dniForm {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding-bottom: 25px;
    align-items: center;
    width: 300px;
}

.dniForm a {
    text-decoration: none;
    color: aliceblue;
    font-size: 14pt;
}

.dniForm input {
    height: 21pt;
   
}

.dniForm button {
    margin-top: 4%;
    width: 35%;
    padding: 5px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
}

.formerror {
    color: rgb(255, 229, 229);
    margin-top: 5px;
    font-size: 10pt;
    width: 100%;
}

.userProfile h3 {
    color: var(--main-orange);
    font-weight: bold;
    float: left;
    text-align: center;
    padding: 29px 26px;
    text-decoration: none;
    font-size: 15px;
    cursor: pointer;
}
.banner {
    margin: 0;
    display: flex;
    background-image: url('../../assets/bgAbstract.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 400px;
    justify-content: center;
}

.bannImg img {
    margin-top: 2%;
    border: solid 5pt rgb(255, 255, 255);

}

.cedula {
    position: absolute;
    display: flex;
    justify-content: center;
    margin-top: 10%;
    width: 100%;
}


@media (orientation:portrait) {
    .banner {
        display: flex;
        justify-content: center;
        background-image: none;
        margin: 0;
        padding: 0;
        height: 200px;
    }

    .bannImg {
        margin-top: 0%;
        height: 200px;
    }

    .bannImg img {
        width: 300px;
    }
}
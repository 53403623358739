.content h1,
p,
.btn_bgstroke {
    margin: 0;
    padding: 0;
    font-family: 'Trebuchet MS', 'Lucida Sans', Arial, sans-serif;
}

.content h1 {
    font-size: 2.8em;
    padding: 10px 0;
    font-weight: 800;
}

.content p {
    font-size: 10pt;
    font-weight: 100;
    letter-spacing: 5px;
}

.content {
    box-shadow: inset 0px 10px 8px 0px #03000048, inset 0px -10px 8px 0px #03000048;
    width: 100%;
    padding: 60px 0;
    text-align: center;
    background: var(--secondary-orange);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3%;
}

.textParticipar {
    width: 40%;
}

.textParticipar p {
    text-align: start;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11pt;
    line-height: 14pt;
    padding: 0 35px;
}

.btn_bgstroke {
    font-size: 20px;
    display: inline-block;
    border: 1px solid white;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 300;
    margin-top: 30px;
}

.btn_bgstroke_logged {
    font-size: 20px;
    display: inline-block;
    border-bottom: 1px solid white;
    ;
    padding: 10px 20px;
    font-weight: 300;
    margin-top: 30px;
}

.btn_bgstroke:hover {
    background-color: white;
    color: var(--secondary-orange);
}

.ByC {
    width: 30%;
    color: var(--secondary-orange);
}

.ByC h2 {
    font-size: 36pt;
    background-color: #ffebd5;
    opacity: 1;
    background-image: radial-gradient(#459ed5 1.6500000000000001px, #ffebd5 1.6500000000000001px);
    background-size: 33px 33px;
    height: 50%;
    border-radius: 45px;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    transition: transform 0.5s;
}

.ByC h2:hover {
    transform: scale(1.1);
}

.textByC {
    width: 80%;
    padding: 20px 5px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
}

.bycContainer {
    height: 90%;
    overflow: scroll;
    background-color: antiquewhite;
}

.bycContainer li {
    font-size: 12pt;
    padding-top: 12pt;
    text-align: start;
    color: var(--secondary-orange);
    padding-left: 15px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    width: fit-content;
    padding-bottom: 15px;
}

.bycContainer h3 {
    display: block;
    float: right;
    margin-top: 7px;
    margin-right: 150px;
    cursor: pointer;
    transition: transform 0.5s;
    color: var(--secondary-orange);
}

.bycContainer h3:hover {
    transform: scale(1.3);
}

.letrachica {
    margin-top: 15px;
}

.letrachica span {
    color: red;
}

.letrachica li {
    font-size: 11pt;
    padding: 2px;
    list-style: none;
    color: black;
}

@media (orientation:portrait) {

    .content {
        display: flex;
        margin-top: 10%;
        flex-direction: column;
        align-items: center;
        flex-direction: column-reverse;
        flex-wrap: wrap;
    }

    .ByC {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        overflow: scroll;
    }

    .ByC h2 {
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 5px;
        width: 70%;
        font-size: 24pt;
    }

    .textByC {
        display: flex;
        flex-direction: column;
        width: fit-content;
        padding: 0;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .bycContainer {
        display: flex;
        justify-content: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .bycContainer li {
        font-size: 9pt;
    }

    .bycContainer h3 {
        margin-left: 100%;
        position: absolute;
    }

    .textParticipar {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }


}